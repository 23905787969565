<template>
    <div class="mob_mint_page">
        <div class="mint_mp01" id="mint_mp01">
            <transition enter-active-class="animated flipInX" leave-active-class="animated slideOutUp">
                <Menu class="mint_menu" v-show="pageMint01Show"/>
            </transition>
            <transition enter-active-class="animated bounceInDown" leave-active-class="animated slideOutUp">
                <img class="mp01_img1" src="../../../assets/images/img_background6_m.png" v-show="pageMint01Show"/>
            </transition>
            <div class="mp01_div1">
                <transition enter-active-class="animated rollIn" leave-active-class="animated slideOutUp">
                    <div class="mp01_div_title" v-show="pageMint01Show">{{isMintOrdinary ? 'Ordinary MetaID Mint' : 'Genesis MetaID Blind-box Pre-sale'}}</div>
                </transition>
                <transition enter-active-class="animated flipInY" leave-active-class="animated slideOutUp">
                    <div class="mp01_div11" v-show="pageMint01Show">
                        <div class="mp01_div111">
                            <div class="div111_l">
                                <img src="../../../assets/images/icon_round.png">
                                <div>Round: {{isMintOrdinary ? (freeSwitch ? 2 : 1) : (indexRound + 1)}}</div>
                            </div>
                            <div class="div111_r">
                                <img src="../../../assets/images/icon_remaining.png">
                                <div>{{isMintOrdinary ? 'Minted' : 'Remaining'}}: {{isMintOrdinary ? (freeSwitch ? freeCount : normalCount) : (indexRound === 0 ? (8000 - sale1) : ((2000 - sale2) + (8000 - sale1)))}}</div>
                            </div>
                        </div>
                        <div class="mp01_div112" v-if="!isMintOrdinary">
                            <img src="../../../assets/images/icon_countdown.png">
                            <div>{{timeText}}</div>
                        </div>
                        <div class="mp01_div113" v-if="!isMintOrdinary">
                            <div class="div113_date" v-for="(obj, index) in dateText.split('')" :key="index">
                                <div class="date_text" v-if="obj !== ':'">
                                    <div class="date_num">
                                        <NumRunout :value="parseInt(obj)"/>
                                    </div>
                                    <div class="date_unit">{{getDateUnitMob(index, dateText.split('').length)}}</div>
                                </div>
                                <div class="date_symbol" v-else>{{ obj }}</div>
                            </div>
                        </div>
                        <div class="mp01_div114" :style="isMintOrdinary ? 'margin-top: 75px' : ''">
                            <div class="sub" @click="toAddSubMob(0)" v-if="!isMintOrdinary">-</div>
                            <div class="sub" style="cursor: not-allowed" v-if="isMintOrdinary">-</div>
                            <div class="num">{{isMintOrdinary ? 1 : rNumber}}</div>
                            <div class="add" @click="toAddSubMob(1)" v-if="!isMintOrdinary">+</div>
                            <div class="add" style="cursor: not-allowed" v-if="isMintOrdinary">+</div>
                        </div>
                        <div class="mp01_div115" @click="mintBoxMob()" v-if="!isMintOrdinary">
                            <div>{{ boxPrice * rNumber}}</div>
                            <img src="../../../assets/images/kava.png" style="width: 24px;">
                            <div class="div115_mint">Mint</div>
                        </div>
                        <div class="mp01_div115" @click="mintOrdinaryMetaIDMob(freeSwitch)" v-if="isMintOrdinary">
                            <div>{{freeSwitch ? '' : metaIdPrice}}</div>
                            <img src="../../../assets/images/kava.png" style="width: 24px;">
                            <div class="div115_mint">Mint</div>
                        </div>
                        <div v-if="!isMintOrdinary" class="mp01_div116">Round 1 only whitelisted addresses Mint. Single address limit Mint: {{indexRound === 0 ? '5' : '1'}}</div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="mint_mp02" id="mint_mp02">
            <transition enter-active-class="animated bounceInDown" leave-active-class="animated slideOutUp">
                <div class="mp02_title" v-show="pageMint02Show">Mint Rules</div>
            </transition>
            <div class="mp02_div1">
                <transition enter-active-class="animated bounceInLeft" leave-active-class="animated slideOutUp">
                    <div class="mp02_div11" v-show="pageMint02Show">
                        <div class="div111">
                            <img src="../../../assets/images/img_rules1.png"/>
                        </div>
                        <div class="div112">
                            <img src="../../../assets/images/img_rules2.png"/>
                        </div>
                        <div class="div113">
                            <img src="../../../assets/images/img_rules3.png"/>
                        </div>
                        <div class="div114">
                            <img src="../../../assets/images/img_rules4.png"/>
                        </div>
                    </div>
                </transition>
                <transition enter-active-class="animated bounceInRight" leave-active-class="animated slideOutUp">
                    <div class="mp02_div12" v-show="pageMint02Show">
                        <div class="div121">
                            MetaID is divided into Genesis and Ordinary, and priority is given to opening genesis metaid NFT blind Box Mint.
                        </div>
                        <div class="div122">
                            The Genesis MetaID blind box adopts the Dutch auction method. The initial price is 1KAVA, and it will decrease by 0.01KAVA every 30 minutes until 0.01KAVA.
                        </div>
                        <div class="div123">
                            The total circulation of Genesis metaid blind box is 10000, which is sold in two rounds. 8000 in the first round, limited to white list users mint, and 2000 in the second round, without white list restrictions. The unsold part of the first round will be automatically allocated to the second round.
                            <p/>
                            - Round1:2023-04-24 18:00:00(UTC)~2023-04-24 24:00:00(UTC)
                            <br/>
                            - Round2:2023-04-25 00:00:00(UTC)~2023-05-10 24:00:00(UTC)
                        </div>
                        <div class="div124">
                            The ordinary MetaID blind box is also divided into two rounds of mint. The first round is limited to free mint and the second round is unlimited to free mint.
                            <p/>
                            - Round1:2023-05-11 00:00:00(UTC)~2023-05-31 24:00:00(UTC)
                            <br/>
                            - Round2:2023-06-01 00:00:00(UTC)~Unlimited
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="mint_mp03" id="mint_mp03">
            <img class="mp03_bj" src="../../../assets/images/img_background7.png"/>
            <div class="mp03_bj_mb"></div>
            <div class="mp03_div">
                <transition enter-active-class="animated rollIn" leave-active-class="animated slideOutUp">
                    <div class="mp03_title" v-show="pageMint03Show">Differences</div>
                </transition>
                <transition enter-active-class="animated bounceInDown" leave-active-class="animated slideOutUp">
                    <div class="mp03_div_all" v-show="pageMint03Show">
                        <div class="mp03_div1">
                            <img src="../../../assets/images/icon_genesis.png"/>
                            <div>
                                Genesis<br/>
                                MetaID
                            </div>
                        </div>
                        <div class="mp03_div2">
                            <img src="../../../assets/images/icon_ordinary.png"/>
                            <div>
                                Ordinary<br/>
                                MetaID
                            </div>
                        </div>
                        <div class="mp03_div3">
                            <div class="one_div">
                                <p class="p_title">Race probability</p>
                                <p>Aliens 5%, apes 15%, humans 80%</p>
                            </div>
                            <div>
                                <p class="p_title">Mint fee</p>
                                <p>0.05KAVA~1KAVA</p>
                            </div>
                            <div>
                                <p class="p_title">Circulation</p>
                                <p>10000</p>
                            </div>
                            <div>
                                <p class="p_title">Avatar</p>
                                <p>Not editable</p>
                            </div>
                            <div>
                                <p class="p_title">Equity weight</p>
                                <p>5</p>
                            </div>
                        </div>
                        <div class="mp03_div4">
                            <div>
                                Dynamic NFT/DID<br/>
                                Asset Bank/Data Storage<br/>
                                Active Level/Earner<br/>
                                Transferable/Openness
                            </div>
                        </div>
                        <div class="mp03_div5">
                            <div class="one_div">
                                <p class="p_title">Race probability</p>
                                <p>Aliens 0.5%, apes 1.5%, humans 98%</p>
                            </div>
                            <div>
                                <p class="p_title">Mint fee</p>
                                <p>Free</p>
                            </div>
                            <div>
                                <p class="p_title">Circulation</p>
                                <p>Unlimited</p>
                            </div>
                            <div>
                                <p class="p_title">Avatar</p>
                                <p>Editable</p>
                            </div>
                            <div>
                                <p class="p_title">Equity weight</p>
                                <p>1</p>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="mint_mp04">
            <img class="p04_img1" src="../../../assets/images/img_background8.png">
            <img class="p04_img2" src="../../../assets/images/img_background9.png">
            <div class="p04_div_all">
                <div class="p04_div1">
                    <div class="p04_div1_title" id="mp04_div1_title">
                        MetaID Avatar
                    </div>
                    <transition enter-active-class="animated fadeInRight" leave-active-class="animated slideOutUp">
                        <div class="p04_div1_data" v-show="pageMint041Show">
                            <img class="p04_data_img" v-for="(img, index) in imgDataArray" :src="img" :key="index">
                        </div>
                    </transition>
                </div>
                <div class="p04_div2">
                    <div class="p04_div2_title" id="mp04_div2_title">
                        MetaID Data
                    </div>
                    <div class="p04_div2_data">
                        <transition enter-active-class="animated flipInX" leave-active-class="animated slideOutUp">
                            <div class="data_statistics" id="mp04_data_statistics" v-show="pageMint042Show"></div>
                        </transition>
                        <transition enter-active-class="animated bounceInDown" leave-active-class="animated slideOutUp">
                            <div class="data_type_text" v-show="pageMint042Show">
                                <div class="datas_type">
                                    <div class="m_holder"></div>
                                    <div>MetaID Holder</div>
                                </div>
                                <div class="datas_type">
                                    <div class="m_minted"></div>
                                    <div>MetaID Minted</div>
                                </div>
                                <div class="datas_type">
                                    <div class="m_value"></div>
                                    <div>MetaID Value</div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="mint_mp05" id="mint_mp05">
            <transition enter-active-class="animated fadeInRight" leave-active-class="animated slideOutUp">
                <Bottom v-show="pageMint05Show"/>
            </transition>
        </div>

        <el-dialog :visible.sync="dialogMintVisible" :show-close="false" :close-on-click-modal="false" width="460px">
            <div class="dialog_gift_div get_metaid_h">
                <div class="dialog_get_metaid">
                    <img class="d_get_meta_img01" src="https://select.ipfs.mintameta.com/ipfs/QmZ1MefUiuCYtuWAv2KugNFmwixGFgAbEsoM1egCgZbxcr"/>
                    <img class="d_get_meta_img02" src="../../../assets/images/bg_getmetaid.png"/>
                    <img class="d_get_meta_img03" src="../../../assets/images/btn_close.png" @click="toCloseGift()"/>
                    <div class="d_get_meta_text_id">Get MetaID #{{openId}}</div>
                    <a>
                        <div class="gift_div03_view" @click="toMetaIdData()">View</div>
                    </a>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogMintBoxmVisible" :show-close="false" :close-on-click-modal="false" width="460px">
            <div class="dialog_gift_div get_metaid_h">
                <div class="dialog_get_metaid">
                    <img class="d_get_meta_img01" src="../../../assets/images/img_blindbox.png"/>
                    <img class="d_get_meta_img02" src="../../../assets/images/bg_getmetaid.png"/>
                    <img class="d_get_meta_img03" src="../../../assets/images/btn_close.png" @click="toCloseGift()"/>
                    <div class="d_get_meta_text_id">Get MetaID blind box × {{rCopyNum}}</div>
                    <a>
                        <div class="gift_div03_view" @click="toMyMetaIdList()">View</div>
                    </a>
                </div>
            </div>
        </el-dialog>

        <MyMetaId v-show="false"/>
    </div>
</template>

<script>
    import Menu from "../../../components/framing/Menu";
    import Bottom from "../../../components/framing/Bottom";
    import NumRunout from "../../../components/framing/NumRunout";
    import MyMetaId from "../../../views/myMetaId/mob/Index";
    import transfer from "../../../tools/transfer";
    import toolUtils from "../../../tools/toolUtils";
    import whitelistAddresses from "../../../tools/whitelistAddresses";
    import {
        BigNumber,
        BigNumberStr,
        BigNumberMul,
        BigNumberDiv,
        BigNumberAdd,
        BigNumberSub,
        setUnit,
        cutAccount
    } from '../../../tools/index';
    import {
        openUrlSLD,
        blindBoxAddress,
        componentAddress,
        metaIdAddress,
        fundAddress
    } from "../../../config";
    import { mixin1 } from "../../../tools/mixins";
    import * as metaMaskTools from "../../../tools/chain";

    const { MerkleTree } = require('merkletreejs');
    const keccak256 = require('keccak256');

    export default {
        name: "Index",
        components: {Menu, Bottom, NumRunout, MyMetaId},
        mixins: [mixin1],
        data() {
            return {
                metaIdAddress,
                isMint: false,
                isMintOrdinary: true,
                pageMint01Show: false,
                pageMint02Show: false,
                pageMint03Show: false,
                pageMint041Show: false,
                pageMint042Show: false,
                pageMint05Show: false,
                normalSwitch: false,
                freeSwitch: false,
                dialogMintVisible: false,
                dialogMintBoxmVisible: false,
                openId: 0,
                initCount: 0,
                limitNum: 0,
                mintNum: 0,
                normalCount: 0,
                freeCount: 0,
                indexRound: 0,
                sale1: 0,
                sale2: 0,
                rNumber: 1,
                rCopyNum: 0,
                boxPrice: 0,
                metaIdPrice: 0,
                startTime1: 0,
                endTime1: 0,
                startTime2: 0,
                endTime2: 0,
                dateNum: 0,
                dateText: '00:00:00:00',
                timeText: 'Start Countdown',
                imgDataArray: [
                    require("../../../assets/images/avatar1.png"),
                    require("../../../assets/images/avatar2.png"),
                    require("../../../assets/images/avatar3.png"),
                    require("../../../assets/images/avatar4.png"),
                    require("../../../assets/images/avatar5.png"),
                    require("../../../assets/images/avatar6.png"),
                    require("../../../assets/images/avatar7.png"),
                    require("../../../assets/images/avatar8.png"),
                    require("../../../assets/images/avatar9.png"),
                    require("../../../assets/images/avatar10.png"),
                    require("../../../assets/images/avatar11.png"),
                    require("../../../assets/images/avatar12.png"),
                    require("../../../assets/images/avatar13.png"),
                    require("../../../assets/images/avatar14.png"),
                    require("../../../assets/images/avatar15.png"),
                    require("../../../assets/images/avatar16.png"),
                    require("../../../assets/images/avatar17.png"),
                    require("../../../assets/images/avatar18.png")
                ],
                legendData: ['Holder', 'Minted', 'Value'],
                xAxisData: ['5.15', '5.16', '5.17', '5.18', '5.19', '5.20', '5.21'],
                mintSeries: [
                    {
                        name: 'Holder',
                        type: 'line',
                        stack: 'Total',
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: 'Minted',
                        type: 'line',
                        stack: 'Total',
                        data: [220, 182, 191, 234, 290, 330, 310]
                    },
                    {
                        name: 'Value',
                        type: 'line',
                        stack: 'Total',
                        data: [150, 232, 201, 154, 190, 330, 410]
                    }
                ]
            }
        },
        watch: {},
        created() {
            window.addEventListener("scroll", this.scrollToTopMintMob);

            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000);
        },
        mounted() {
            this.getDateTextMob();
            if (!toolUtils.isPC()) {
                this.getMetaIdPriceMob();
                this.getBoxPriceMob();
                this.getBoxRoundMob();
                this.getBoxCountInfoMob();
            }

            transfer.$emit("initMenuPage");

            setTimeout(() => {
                this.pageMint01Show = true;
            }, 200);
        },
        destroyed() {
            window.removeEventListener('scroll', this.scrollToTopMintMob);
        },
        methods: {
            async onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });
                this.getDateTextMob();
                if (!toolUtils.isPC()) {
                    this.getMetaIdPriceMob();
                    this.getBoxPriceMob();
                    this.getBoxRoundMob();
                    this.getBoxCountInfoMob();
                }
            },
            async getBoxPriceMob() {
                try {
                    let params = [];
                    await metaMaskTools.blindBoxPublicCall("getPrice", params).then(async (data) => {
                        console.log(BigNumberDiv(data, 1e18));
                        this.boxPrice = BigNumberDiv(data, 1e18);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async getMetaIdPriceMob() {
                try {
                    await metaMaskTools.metaIdPublicCall("price", []).then(async (data) => {
                        console.log(BigNumberDiv(data, 1e18));
                        this.metaIdPrice = BigNumberDiv(data, 1e18);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async getBoxRoundMob() {
                try {
                    let params = [];
                    params.push(this.indexRound);
                    await metaMaskTools.blindBoxPublicCall("getRound", params).then(async (data) => {
                        console.log(data);

                        if (this.indexRound === 0) {
                            this.sale1 = data.sale;
                            this.startTime1 = data.start;
                            this.endTime1 = data.end;
                        } else {
                            this.sale2 = data.sale;
                            this.startTime2 = data.start;
                            this.endTime2 = data.end;
                        }

                        let dateTime = parseInt(new Date().getTime() / 1000);

                        if (dateTime >= this.endTime1 && this.indexRound === 0) {
                            this.indexRound = 1;
                            setTimeout(() => {
                                this.getBoxRoundMob();
                            }, 500);
                        }

                        if (this.indexRound === 0) {
                            this.isMintOrdinary = false;
                            if (dateTime < this.startTime1) {
                                this.isMint = false;
                                this.timeText = 'Start Countdown';
                                this.dateNum = (this.startTime1 - dateTime);
                            }
                            if (dateTime >= this.startTime1 && dateTime < this.endTime1) {
                                this.isMint = true;
                                this.timeText = 'End countdown';
                                this.dateNum = (this.endTime1 - dateTime);
                            }
                        } else {
                            this.isMintOrdinary = false;
                            if (dateTime >= this.endTime1 && dateTime < this.startTime2) {
                                this.isMint = false;
                                this.timeText = 'Start Countdown';
                                this.dateNum = (this.startTime2 - dateTime);
                            }
                            if (dateTime >= this.startTime2 && dateTime < this.endTime2) {
                                this.isMint = true;
                                this.timeText = 'End countdown';
                                this.dateNum = (this.endTime2 - dateTime);
                            }
                            if (dateTime >= this.endTime2) {
                                this.isMintOrdinary = false;
                                this.isMint = false;
                                this.timeText = 'Purchase ended';
                                this.dateNum = 0;
                            }
                        }

                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async mintBoxMob() {
                try {
                    if (!this.isMint) {
                        let dateTime = parseInt(new Date().getTime() / 1000);

                        if (this.indexRound === 0) {
                            if (dateTime < this.startTime1) {
                                this.$message.warning('Purchase not started!');
                            }
                        } else {
                            if (dateTime >= this.endTime1 && dateTime < this.startTime2) {
                                this.$message.warning('Purchase not started!');
                            }
                            if (dateTime >= this.endTime2) {
                                this.$message.warning('Purchase ended!');
                            }
                        }
                        return;
                    }
                    if (this.$store.state.accounts.length === 0) {
                        this.checkWalletm();
                        return;
                    }
                    if (this.rNumber <= 0) {
                        this.$message.warning('Mint quantity cannot be less than 0!');
                        return;
                    }
                    if (this.mintNum >= this.limitNum || (this.mintNum + this.rNumber) > this.limitNum) {
                        this.$message.warning('Exceed available quota!');
                        return;
                    }
                    let leafNodes = whitelistAddresses.map(address => keccak256(address));
                    let tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
                    let leaf = keccak256(this.$store.state.accounts[0]);
                    let proof = tree.getHexProof(leaf);

                    let params = [];
                    params.push(this.rNumber);
                    params.push(proof);

                    let value = this.rNumber * BigNumberMul(this.boxPrice, 1e18);

                    await metaMaskTools.blindBoxMintSend("mint", params, value).then(async (data) => {
                        console.log(data);
                        this.getBoxPriceMob();
                        this.getBoxRoundMob();
                        this.getBoxCountInfoMob();
                        this.rCopyNum = this.rNumber;
                        this.dialogMintBoxmVisible = true;
                        this.rNumber = 1;
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            async mintOrdinaryMetaIDMob(isFree) {
                try {
                    if (this.$store.state.accounts.length === 0) {
                        this.checkWalletm();
                        return;
                    }
                    if (isFree) {
                        metaMaskTools.metaIdPayableSend("freeMint", [], 0).then(async (data) => {
                            console.log(data);
                            this.getMintMetaId();
                            this.getMetaIdPriceMob();
                            this.getBoxRoundMob();
                            this.getBoxCountInfoMob();
                        });
                    } else {
                        if (this.metaIdPrice <= 0) {
                            await this.getMetaIdPriceMob();
                        }
                        let value = 1 * BigNumberMul(this.metaIdPrice, 1e18);
                        await metaMaskTools.metaIdPayableSend("normalMint", [], value).then(async (data) => {
                            console.log(data);
                            this.getMintMetaId();
                            this.getMetaIdPriceMob();
                            this.getBoxRoundMob();
                            this.getBoxCountInfoMob();
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            async getBoxCountInfoMob() {
                try {
                    await metaMaskTools.metaIdPublicCall("normalSwitch", []).then(async (data) => {
                        console.log(data);
                        this.normalSwitch = data;
                    });
                    await metaMaskTools.metaIdPublicCall("freeSwitch", []).then(async (data) => {
                        console.log(data);
                        this.freeSwitch = data;
                    });
                    await metaMaskTools.metaIdPublicCall("initCount", []).then(async (data) => {
                        console.log(data);
                        this.initCount = data;
                    });
                    await metaMaskTools.metaIdPublicCall("normalCount", []).then(async (data) => {
                        console.log(data);
                        this.normalCount = data;
                    });
                    await metaMaskTools.metaIdPublicCall("freeCount", []).then(async (data) => {
                        console.log(data);
                        this.freeCount = data;
                    });
                    await metaMaskTools.blindBoxPublicCall("LIMIT", []).then(async (data) => {
                        console.log(data);
                        this.limitNum = data;
                    });
                    if (this.$store.state.accounts.length > 0) {
                        await metaMaskTools.blindBoxPublicCall("mintNum", [this.$store.state.accounts[0]]).then(async (data) => {
                            console.log(data);
                            this.mintNum = data;
                        });
                    } else {
                        this.mintNum = 0;
                    }
                } catch (err) {
                    console.log(err);
                }
            },
            scrollToTopMintMob() {
                let that = this;
                // type==1表示可见，type==0表示不可见
                let osv = new toolUtils.osVisible();

                osv.init("#mint_mp02", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.pageMint02Show = true;
                        }, 300);
                    }
                }, 1);

                osv.init("#mint_mp03", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.pageMint03Show = true;
                        }, 300);
                    }
                }, 1);

                osv.init("#mp04_div1_title", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.pageMint041Show = true;
                        }, 300);
                    }
                }, 1);

                osv.init("#mp04_div2_title", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            if (that.pageMint042Show === false) {
                                that.initDataStatisticsMob();
                            }
                            that.pageMint042Show = true;
                        }, 300);
                    }
                }, 1);

                osv.init("#mint_mp05", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.pageMint05Show = true;
                        }, 300);
                    }
                }, 1);
            },
            getDateUnitMob(index) {
                if (index === 1) {
                    return 'D';
                } else if (index === 4) {
                    return 'H';
                } else if (index === 7) {
                    return 'M';
                } else if (index === 10) {
                    return 'S';
                }
            },
            getDateTextMob() {
                if (this.dateNum > 0) {
                    this.dateText = toolUtils.conversionTimestampFormat(this.dateNum);
                } else {
                    if (!this.isMintOrdinary) {
                        setTimeout(async () => {
                            await this.getMetaIdPriceMob();
                            await this.getBoxPriceMob();
                            await this.getBoxRoundMob();
                            await this.getBoxCountInfoMob();
                            await this.getDateTextMob();
                        }, 1000);
                    }
                }
                setTimeout(() => {
                    this.dateNum--;
                    this.getDateTextMob();
                }, 1000);
            },
            toAddSubMob(type) {
                if (type === 1) {
                    if (this.indexRound === 0 ? (this.rNumber === 5) : (this.rNumber === 1)) {
                        this.$message.warning('Purchase up to ' + (this.indexRound === 0 ? 5 : 1) + ' at a time!');
                        return;
                    }
                    this.rNumber++;
                } else {
                    if (this.rNumber > 1) {
                        this.rNumber--;
                    } else {
                        this.$message.warning('Wrong operation!');
                    }
                }
            },
            initDataStatisticsMob() {
                // 调用echarts
                var echarts = require('echarts')

                if (document.getElementById('mp04_data_statistics') == null) {
                    return;
                }
                echarts.dispose(document.getElementById('mp04_data_statistics'));

                this.$nextTick(function () {
                    // 初始化echarts实例 -- 折线图
                    var mintDataLine = echarts.init(document.getElementById('mp04_data_statistics'));
                    window.onresize = function() {
                        mintDataLine.resize();
                    };
                    mintDataLine.showLoading();

                    // 指定图表的配置项和数据
                    var optionLine = {
                        title: {
                            text: ''
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: this.legendData
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        toolbox: {
                            feature: {}
                        },
                        // 调色盘。
                        color: ['#04A7BC', '#7D69EC', '#FF9900'],
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: this.xAxisData
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: this.mintSeries
                    };

                    // 使用刚指定的配置项和数据显示图表。
                    mintDataLine.hideLoading();
                    mintDataLine.setOption(optionLine);
                });
            },
            toCloseGift() {
                this.dialogMintVisible = false;
                this.dialogMintBoxmVisible = false;
            },
            toMetaIdData() {
                this.dialogMintVisible = false;
                this.$store.commit('setState', {
                    toastShow: false
                });
                let obj = { id: this.openId };

                if (parseInt(this.openId) > 10000) {
                    setTimeout(() => {
                        transfer.$emit("toDetailm", obj);
                    }, 100);
                } else {
                    setTimeout(() => {
                        transfer.$emit("toDetailGenm", obj);
                    }, 100);
                }
            },
            toMyMetaIdList() {
                sessionStorage.setItem("menuIndex", 3);
                transfer.$emit("initMenuPage");
                this.dialogMintBoxmVisible = false;
                this.$store.commit('setState', {
                    toastShow: false
                });
                this.$router.push("/mymetaid");
            },
            getMintMetaId() {
                metaMaskTools.erc721BasicPublicCall(metaIdAddress, "balanceOf", [this.$store.state.accounts[0]]).then(async (data) => {
                    metaMaskTools.erc721BasicPublicCall(metaIdAddress, "tokenOfOwnerByIndex", [this.$store.state.accounts[0], (parseInt(data) - 1)]).then(async (id) => {
                        this.openId = id;
                        this.dialogMintVisible = true;
                    });
                });
            },
            checkWalletm() {
                if (this.$store.state.accounts.length === 0) {
                    this.$message.warning('Please connect wallet first!');
                    setTimeout(() => {
                        this.$store.commit('setState', {
                            connectWalletShow: true
                        })
                    }, 1500);
                }
            }
        }
    }
</script>

<style lang="less">
    .mob_mint_page {
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        width: 100%;
        .mint_mp01 {
            width: 100%;
            height: 980px;
            background: #7662EA;
            position: relative;
            z-index: 20;
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 93%);
            .mint_menu {
                position: absolute;
                top: 10px;
                z-index: 10;
            }
            .mp01_img1 {
                width: 92%;
                height: 100%;
                margin-left: 4%;
            }
            .mp01_div1 {
                position: absolute;
                top: 150px;
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                .mp01_div_title {
                    text-align: center;
                    font-size: 40px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #F9F7F3;
                    line-height: 55px;
                    text-shadow: -4px 3px 0px #141315;
                    -webkit-text-stroke: 3px #141315;
                    text-stroke: 3px #141315;
                }
                .mp01_div11 {
                    width: 420px;
                    height: 520px;
                    background: #F9F7F3;
                    box-shadow: -8px 8px 0px 0px #141315;
                    border-radius: 30px;
                    border: 6px solid #141315;
                    position: absolute;
                    top: 160px;
                    margin-left: calc((100% - 420px) / 2);
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    .mp01_div111 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 34px;
                        width: 100%;
                        margin: 30px 0;
                        .div111_l {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            margin: 0 25px;
                        }
                        .div111_r {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: flex-end;
                            margin: 0 25px;
                        }
                    }
                    .mp01_div112 {
                        width: 180px;
                        height: 40px;
                        font-size: 18px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #A4A2A6;
                        line-height: 40px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .mp01_div113 {
                        zoom: 0.45;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: flex-end;
                        margin-top: 50px;
                        .div113_date {
                            margin: 5px;
                            position: relative;
                            .date_text {
                                width: 90px;
                                height: 90px;
                                background: #070707;
                                border-radius: 12px;
                                .date_num {
                                    color: #FFFFFF;
                                    font-size: 50px;
                                    line-height: 90px;
                                    text-align: center;
                                }
                                .date_unit {
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    color: #FFFFFF;
                                    font-size: 12px;
                                    margin-left: 65px;
                                    top: 60px;
                                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                }
                            }
                            .date_symbol {
                                font-size: 35px;
                                font-weight: 600;
                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            }
                        }
                    }
                    .mp01_div114 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: space-between;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        margin-top: 40px;
                        .sub {
                            width: 60px;
                            height: 60px;
                            line-height: 60px;
                            font-size: 40px;
                            text-align: center;
                            cursor: pointer;
                            color: white;
                            background: #F7A072;
                            border-radius: 16px;
                        }
                        .num {
                            width: 160px;
                            height: 60px;
                            background: #FFFFFF;
                            border-radius: 16px;
                            font-size: 30px;
                            font-weight: bold;
                            color: #141315;
                            line-height: 60px;
                            text-align: center;
                        }
                        .add {
                            width: 60px;
                            height: 60px;
                            line-height: 60px;
                            font-size: 40px;
                            text-align: center;
                            cursor: pointer;
                            color: white;
                            background: #F7A072;
                            border-radius: 16px;
                        }
                    }
                    .mp01_div115 {
                        width: 280px;
                        height: 70px;
                        background: #F9F7F3;
                        box-shadow: -6px 6px 0px 0px #141315;
                        border-radius: 16px;
                        border: 4px solid #141315;
                        margin: 35px 0 25px 3px;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: nowrap;
                        .div115_mint {
                            margin-left: 20px;
                        }
                        div {
                            height: 39px;
                            font-size: 30px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #141315;
                            line-height: 39px;
                        }
                        img {
                            margin: 0 5px;
                        }
                    }
                    .mp01_div116 {
                        height: 18px;
                        font-size: 14px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #A4A2A6;
                        line-height: 18px;
                        text-align: center;
                    }
                }
            }
        }
        .mint_mp02 {
            width: 90%;
            height: 1050px;
            margin-left: 5%;
            background: #F9F7F3;
            position: relative;
            .mp02_title {
                height: 60px;
                font-size: 55px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #141315;
                line-height: 60px;
                margin: 50px 0 60px 0;
            }
            .mp02_div1 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;
                .mp02_div11 {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 110px;
                    height: 850px;
                    border-right: 1px solid #070707;
                    .div111 {
                        height: 90px;
                        img {
                            width: 90px;
                        }
                    }
                    .div112 {
                        height: 115px;
                        img {
                            width: 90px;
                        }
                    }
                    .div113 {
                        height: 350px;
                        img {
                            width: 90px;
                        }
                    }
                    .div114 {
                        height: 245px;
                        img {
                            width: 90px;
                        }
                    }
                }
                .mp02_div12 {
                    width: 70%;
                    font-size: 16px;
                    font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                    font-weight: 600;
                    color: #141315;
                    line-height: 23px;
                    margin-left: 20px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    .div121 {
                        height: 90px;
                    }
                    .div122 {
                        height: 115px;
                    }
                    .div123 {
                        height: 360px;
                    }
                    .div124 {
                        height: 245px;
                    }
                }
            }
        }
        .mint_mp03 {
            width: 100%;
            height: 1160px;
            background: rgba(20, 19, 21, 1);
            position: relative;
            z-index: 20;
            clip-path: polygon(0% 7%, 100% 0%, 100% 100%, 0% 93%);
            .mp03_bj {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .mp03_bj_mb {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(20, 19, 21, 0.8);
            }
            .mp03_div {
                position: absolute;
                width: 100%;
                margin-top: 70px;
                .mp03_title {
                    width: 445px;
                    height: 102px;
                    font-size: 55px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #F9F7F3;
                    line-height: 102px;
                    margin: 65px auto;
                    text-align: center;
                }
                .mp03_div_all {
                    position: relative;
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    .mp03_div1 {
                        position: absolute;
                        top: 0;
                        left: 5%;
                        width: 120px;
                        height: 120px;
                        background: #7D69EC;
                        border-radius: 50%;
                        text-align: center;
                        color: #FFFFFF;
                        z-index: 10;
                        img {
                            margin-top: 20px;
                        }
                        div{
                            height: 56px;
                            font-size: 17px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #F9F7F3;
                            line-height: 20px;
                        }
                    }
                    .mp03_div2 {
                        position: absolute;
                        top: 550px;
                        left: 5%;
                        width: 120px;
                        height: 120px;
                        background: #59B0DF;
                        border-radius: 50%;
                        text-align: center;
                        color: #FFFFFF;
                        z-index: 10;
                        img {
                            margin-top: 20px;
                        }
                        div{
                            height: 56px;
                            font-size: 17px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #F9F7F3;
                            line-height: 20px;
                        }
                    }
                    .mp03_div3 {
                        position: absolute;
                        top: 0;
                        left: calc((100% - 420px) / 2);
                        width: 420px;
                        height: 420px;
                        background: url("../../../assets/images/bg_difference1_1.png");
                        background-size: 100% 100%;
                        z-index: 9;
                        div {
                            width: 300px;
                            margin-left: 125px;
                        }
                        p {
                            margin: 0 0 8px 0;
                            font-size: 14px;
                            font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                            font-weight: 500;
                            color: #141315;
                        }
                        .p_title {
                            font-size: 18px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #141315;
                            line-height: 18px;
                            margin: 0;
                        }
                        .one_div {
                            margin-top: 50px;
                        }
                    }
                    .mp03_div4 {
                        position: absolute;
                        top: 262px;
                        left: calc((100% - 330px) / 2);
                        width: 330px;
                        height: 155px;
                        background: url("../../../assets/images/bg_difference3_m.png");
                        background-size: 100% 100%;
                        z-index: 10;
                        div {
                            width: 100%;
                            margin-top: 25px;
                            font-size: 17px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #141315;
                            line-height: 25px;
                            text-align: center;
                        }
                    }
                    .mp03_div5 {
                        position: absolute;
                        top: 260px;
                        left: calc((100% - 420px) / 2);
                        width: 420px;
                        height: 420px;
                        background: url("../../../assets/images/bg_difference2_1.png");
                        background-size: 100% 100%;
                        z-index: 9;
                        div {
                            width: 300px;
                            margin-left: 125px;
                        }
                        p {
                            margin: 0 0 8px 0;
                            font-size: 14px;
                            font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                            font-weight: 500;
                            color: #141315;
                        }
                        .p_title {
                            font-size: 18px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #141315;
                            line-height: 18px;
                            margin: 0;
                        }
                        .one_div {
                            margin-top: 170px;
                        }
                    }
                }
            }
        }
        .mint_mp04 {
            width: 100%;
            height: 1865px;
            background: #B6E2FA;
            position: relative;
            margin-top: -68px;
            .p04_img1 {
                position: absolute;
                top: 0;
                left: 0;
            }
            .p04_img2 {
                position: absolute;
                right: 0;
                bottom: 150px;
            }
            .p04_div_all {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                .p04_div1 {
                    width: 90%;
                    margin-left: 5%;
                    .p04_div1_title {
                        text-align: center;
                        height: 80px;
                        font-size: 55px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 80px;
                        margin-top: 120px;
                    }
                    .p04_div1_data {
                        margin-top: 60px;
                        margin-bottom: 50px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-content: space-between;
                        align-items: center;
                        .p04_data_img {
                            margin: 20px 23px;
                            zoom: 0.55;
                        }
                    }
                }
                .p04_div2 {
                    width: 90%;
                    margin-left: 5%;
                    .p04_div2_title {
                        text-align: center;
                        height: 80px;
                        font-size: 55px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 80px;
                    }
                    .p04_div2_data {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                        .data_statistics {
                            width: 360px;
                            height: 515px;
                            margin-top: 60px;
                            -moz-transform: scale(1);
                            -moz-transform-origin: 0 0;
                            background: #F9F7F3;
                            box-shadow: -8px 8px 0px 0px #A4CFE5;
                            border-radius: 8px;
                            zoom: 1.15;
                        }
                        .data_type_text {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: space-between;
                            .datas_type {
                                height: 42px;
                                width: 138px;
                                font-size: 17px;
                                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                                font-weight: bold;
                                color: #141315;
                                line-height: 32px;
                                margin: 50px 0 0 0;
                                text-align: right;
                            }
                            .m_holder {
                                width: 80px;
                                height: 5px;
                                background: #04A7BC;
                                border-radius: 2px;
                                margin: 10px 0 15px calc(132px - 82px);
                            }
                            .m_minted {
                                width: 80px;
                                height: 5px;
                                background: #7D69EC;
                                border-radius: 2px;
                                margin: 10px 0 15px calc(132px - 82px);
                            }
                            .m_value {
                                width: 80px;
                                height: 5px;
                                background: #FF9900;
                                border-radius: 2px;
                                margin: 10px 0 15px calc(132px - 82px);
                            }
                        }
                    }
                }
            }
        }
        .mint_mp05 {
            width: 100%;
            height: 240px;
            background: #070707;
        }
    }
</style>
